import {
	asyncWithLDProvider,
	useFlags,
	useLDClient,
} from 'launchdarkly-react-client-sdk';

import { UUID_COOKIE_NAME } from 'stash/constants/session';
import { LAUNCH_DARKLY_CLIENT_IDS } from 'stash/constants/ab-testing';
import { getCookie } from 'stash/utils/cookies';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { userSelector } from 'stash/selectors/user';
import { useEffect, useRef } from 'react';

function createContext(uuid) {
	const context = {
		kind: 'user',
	};

	if (uuid) {
		context.key = uuid;
	} else {
		context.anonymous = true;
	}
	return context;
}

/**
 * Experiment keys of current Launch Darkly ab tests.
 */
export const EXPERIMENTS = {
	ALLOW_PPM_USER_SETTINGS: '04-12-24-web-preferred-payment-method-user-settings',
	IS_SDS_RAIL_ENABLED: 'isSdsRailEnabled',
	IS_NS_HOME_REDESIGN_ENABLED: 'is-ns-home-redesign-enabled-v1',
	IS_T_PLUS_ONE_ENABELED: 'is-one-day-transfers-enabled',
	IS_CHURN_PROMO_ENABLED: '06-04-24-web-active-churn-promo-growth-monthly',
	IS_BANK_LINK_IN_CANCEL_FLOW_ENABLED: '06-22-2024-web-bank-link-in-cancel-flow',
	IS_NS_TRANSFER_UI_REFRESH_ENABLED: 'is-ns-transfer-ui-refresh-enabled',
	IS_DYNAMIC_TRANSFER_LIMITS_ENABLED: 'is-dynamic-transfer-limits-enabled',
	IS_AUTO_STASH_PARITY_ENABLED: 'is-auto-stash-parity-enabled',
	ENABLE_PLAN_DETAILS_POST_CANCEL_UX: '08-20-24-web-plan-details-post-cancel-states',
	IS_NEW_DIV_SCORE_ENABLED: 'is-new-div-score-enabled-web',
};

export const getLaunchDarklyProvider = () => {
	const clientSideID = LAUNCH_DARKLY_CLIENT_IDS[window.Stash.env];

	return asyncWithLDProvider({
		context: createContext(getCookie(UUID_COOKIE_NAME)),
		clientSideID,
		options: {
			streaming: false,
			disableSyncEventPost: true,
		},
		reactOptions: {
			useCamelCaseFlagKeys: false,
		},
	});
};

export function ExperimentsContextUpdater() {
	const mountedRef = useRef(false);
	const client = useLDClient();
	const user = useSelector(userSelector);

	useEffect(() => {
		// skip the first render since the provider will make the initial call
		if (mountedRef.current) {
			client.identify(createContext(user?.uuid));
		}
		mountedRef.current = true;
	}, [user?.uuid]);

	return null;
}

function useCurrentVariant(experiment) {
	const flags = useFlags();
	const localTestVariant = localStorage.getItem(experiment);
	const hasLocalTestVariant = localTestVariant !== null && localTestVariant !== undefined;

	if (hasLocalTestVariant) {
		switch (localTestVariant) {
			case 'default':
				return undefined;
			case 'false':
				return false;
			case 'true':
				return true;
			default:
				return localTestVariant;
		}
	}

	return flags[experiment];
}

/**
 * Renders DOM elements when in the provided experiment variant.
 */
export function ExperimentVariant({ experiment, variant, defaultVariant, children }) {
	const currentVariant = useCurrentVariant(experiment);

	if (defaultVariant && currentVariant === undefined) {
		return children;
	}

	return variant === currentVariant ? children : null;
}

/**
 * Returns true if the user is in the provided experiment variant.
 * For pure business logic; useful when not rendering DOM elements.
 */
export function useIsInExperimentVariation(experiment, variant) {
	const currentVariant = useCurrentVariant(experiment);
	return variant === currentVariant;
}

const VariantPropType = PropTypes.oneOfType([PropTypes.bool, PropTypes.string]);

ExperimentVariant.propTypes = {
	experiment: PropTypes.string.isRequired,
	variant: VariantPropType.isRequired,
	defaultVariant: PropTypes.bool,
	children: PropTypes.node.isRequired,
};
