import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardReady } from 'stash/actions/dashboard';
import { push } from 'connected-react-router';
import {
	getAccountPortfolio,
	getAccountAutoStash,
	getAccountCards,
	getAccountBankAccounts,
	getExternalAccount,
	getFundingSources,
	getBookmarks,
	getActivePlatformTier,
	getLaunchInfo,
} from 'stash/actions/api/';
import { ARCHIVE_RESTRICTED_REASONS } from 'stash/constants/mappings';
import { PERSONAL_BROKERAGE } from 'stash/constants/accountTypes';
import { hasClosedAllAccounts } from 'stash/utils/helpers';
import {
	LAUNCH_INFO,
	REG_CONTENT_HOME,
	REG_INITIAL,
	REG_INITIAL_SHORT,
	authorizedContentMoatUrls,
	HOME,
	REG_STATE_ZERO,
	isAllowedToRenderWebApp,
	STATE_ZERO_OID,
	isStateZeroPending,
} from 'stash/constants/launchInfo';
import { Layout } from '@stashinvest/ui';
import DuplicateAccountModal from '../duplicateAccountModal';
import { logOut, logOutReasons } from '../../actions';
import { useLocation } from 'react-router-dom';
import { selectIsHome } from 'stash/selectors';
import { useCachedGet } from '@stashinvest/use-fetch';
import useSendAnalytics from 'stash/utils/instrumentation/sendAnalytics';

const DashboardContainer = (props) => {
	const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
	const ready = useSelector((state) => state.application.dashboard);
	const access_token = useSelector((state) => state.entities.api_key.access_token);
	const isHome = useSelector(selectIsHome);
	const [accounts, setAccounts] = useState([]);
	const [finalPath, setFinalPath] = useState('');
	const dispatch = useDispatch();
	const location = useLocation();
	const { sendAnalyticData } = useSendAnalytics();

	// No error scenario for below API. If it fails then the app will continue and load Home.
	const { fetch: getPxQuestions } = useCachedGet({
		path: `/apis/customers/v1/questions/${STATE_ZERO_OID}`,
		onSuccess: ({ question }) => {
			if (isStateZeroPending(question?.answer?.value) && !isAllowedToRenderWebApp()) {
				redirectToStateZero();
			}
		},
	});

	useEffect(() => {
		if (access_token) {
			getDashboardContent();
		} else {
			navigateToLogIn();
		}
	}, [access_token]);

	useEffect(() => {
		if (location.pathname === '/' && isHome) {
			runStateZeroChecks();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, isHome]);

	const getAccountContent = ({ id }) => {
		return [
			dispatch(getAccountPortfolio(id)),
			dispatch(getAccountCards(id)),
			dispatch(getAccountAutoStash(id)),
			dispatch(getAccountBankAccounts(id)),
		];
	};

	const prepareDashboard = (data) => {
		const accountContent = data.map(getAccountContent).reduce((acc, accountContent) => {
			return [...acc, ...accountContent];
		}, []);

		return Promise.all([
			dispatch(getFundingSources()),
			dispatch(getActivePlatformTier()),
			dispatch(getExternalAccount()),
			...accountContent,
		]);
	};

	const onCloseModal = () => {
		setIsDuplicateModalOpen(false);
		dispatch(logOut(logOutReasons.DUPLICATE_LOGIN));
	};

	const onContinueClicked = () => {
		prepareDashboard(accounts)
			.then(() => {
				setIsDuplicateModalOpen(false);
				if (finalPath) {
					dispatch(push(finalPath));
				}
			})
			.catch((err) => {
				console.error(err);
				return err;
			})
			.then(() => {
				dispatch(getBookmarks());
				return dispatch(dashboardReady());
			});
	};

	const redirectToStateZero = () => {
		window.location.href =
			window.location.hostname === 'app-local.stash.com'
				? `https://app-local.stash.com:9009/sign-up/get-started`
				: LAUNCH_INFO[REG_STATE_ZERO].route;
	};

	const runStateZeroChecks = () => {
		dispatch(getExternalAccount()).then((res) => {
			if (!Boolean(res?.manageExternalBankAccount?.bankAccount)) {
				getPxQuestions();
			}
		});
	};

	const getDashboardContent = () => {
		return dispatch(getLaunchInfo())
			.then((res) => {
				const { location, user } = res;
				const { restricted_reason } = user;
				setAccounts(res.accounts);
				// Only used for checking for inactive state
				const investAccount = res.accounts.find((acc) => acc.type === PERSONAL_BROKERAGE);

				const hasInactiveInvestAccount =
					investAccount && investAccount.state === 'inactive';

				const showContentMoat =
					LAUNCH_INFO[location] &&
					location === REG_CONTENT_HOME &&
					!authorizedContentMoatUrls[window.location.pathname];

				const showOnboarding = LAUNCH_INFO[location] && location === REG_INITIAL;
				const showOnboarding2 = LAUNCH_INFO[location] && location === REG_INITIAL_SHORT;
				const showHome = location === HOME;

				if (!showOnboarding && !showOnboarding2 && user?.uuid) {
					sendAnalyticData(user.uuid);
				}

				// Handle re-directs for closed / restricted / incomplete accounts / duplicate accounts
				if (restricted_reason === 'DUPLICATE_ACCOUNT') {
					setIsDuplicateModalOpen(true);
					if (showContentMoat) {
						return setFinalPath(LAUNCH_INFO[REG_CONTENT_HOME].route);
					} else if (showOnboarding || showOnboarding2) {
						return setFinalPath(LAUNCH_INFO[REG_INITIAL].route);
					} else if (showHome) {
						return setFinalPath('/');
					}
				} else if (
					!showContentMoat &&
					(hasClosedAllAccounts(accounts) ||
						ARCHIVE_RESTRICTED_REASONS[restricted_reason])
				) {
					return dispatch(push('/archive/documents'));

					// Handle inactive personal brokerage accounts
				} else if (hasInactiveInvestAccount && restricted_reason === 'USER_LOCKED') {
					return dispatch(push('/account-locked'));
				} else if (
					hasInactiveInvestAccount &&
					restricted_reason &&
					restricted_reason !== 'DUPLICATE_ACCOUNT'
				) {
					return dispatch(push('/reactivate-account'));
				} else if (showContentMoat) {
					redirectToStateZero();
				} else if (showOnboarding) {
					window.location.href = LAUNCH_INFO[REG_INITIAL].route;
				} else if (showOnboarding2) {
					window.location.href = LAUNCH_INFO[REG_INITIAL_SHORT].route;
				}

				if (location === HOME) {
					runStateZeroChecks();
				}
				return prepareDashboard(res.accounts);
			})
			.then(() => {
				dispatch(getBookmarks());
				return dispatch(dashboardReady());
			})
			.catch((err) => {
				console.error(err);
				return err;
			});
	};
	const navigateToLogIn = () => {
		return dispatch(push('/log-in'));
	};

	if (isDuplicateModalOpen) {
		return <DuplicateAccountModal onSubmit={onContinueClicked} onClose={onCloseModal} />;
	}

	return ready ? (
		props.children
	) : (
		<>
			<Layout.PageWrapLoader />
		</>
	);
};

DashboardContainer.propTypes = {
	children: PropTypes.any,
	location: PropTypes.object,
};

export default DashboardContainer;
